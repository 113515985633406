/**
 * @module ListItem
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { CONTENT_MODES, LAYOUT_MODES } from '../../utils';
import './ListItem.scss';

/**
 * Represents a List Item form control element.
 *
 * @param {object} props - The component props object.
 * @param {*} [props.actionIcon] - The action icon to include, positioned on the far right side of the component (Default: Right-facing caret arrow icon).
 * @param {string} [props.description] - Optional description (sub-text) for the component.
 * @param {'dangerouslySetInnerHTML'|'default'} [props.descriptionMode] - Optional mode to render the description (Default: 'default').
 * @param {endIcon} [props.endIcon] - Optional icon that will render on the right of the component, in addition to, and to the left of, the actionIcon (if visible).
 * @param {string} props.inputName - The name of the input, used as <input> element `name` attribute.
 * @param {string} props.isWholeListItemClickable - Optional boolean flag denoting whether or not the whole list item should be clickable via the absolute-positioned overlay (Default: true).
 * @param {string} [props.label] - Optional label to display next to the title.
 * @param {'horizontal'|'vertical'} [props.layout] - Optional layout direction for item content (Default: 'horizontal').
 * @param {Function} [props.onClick] - Handler function for click events.
 * @param {showActionIcon} [props.showActionIcon] - Boolean flag denoting whether or not to show the action icon (Default: true).
 * @param {*} [props.startIcon] - Optional icon to render on the left side of the component.
 * @param {string} [props.title] - The main text title content for the component.
 * @param {TitleProps} [props.titleProps] - Optional title props to apply to the title element.
 *
 * @returns {React.ReactElement} The ListItem component.
 */
export function ListItem({
  actionIcon,
  description,
  descriptionMode = CONTENT_MODES.default,
  endIcon,
  inputName,
  isWholeListItemClickable = true,
  label,
  layout = LAYOUT_MODES.horizontal,
  onClick = () => {},
  showActionIcon = true,
  startIcon,
  title,
  titleProps = { large: false, primary: false },
  ...passThroughProps
}) {
  /**
   * Note: With the addition of passThroughProps (i.e. `rest`), it allows the
   * component to get and use additional props, such as `className`. As such,
   * use a dynamically-built list of class names, based on the standard for the
   * component, but adding in additionally-provided values as well.
   */
  const wrapperClass = [
    'form-control',
    'list-item',
    passThroughProps?.className || '',
  ].join(' ');

  return (
    <div
      className={wrapperClass}
      data-testid={passThroughProps['data-testid'] || 'list-item'}
      id={passThroughProps.id || null}
    >
      <div className="label-wrap">
        <div className={`content-${layout}`}>
          {startIcon || null}
          {label ? <div className="label-text">{label}</div> : null}
          <div className="text text-container">
            {title ? (
              <div
                className={[
                  titleProps?.primary ? 'primary' : '',
                  titleProps?.large ? 'large' : '',
                  'title-text',
                ]
                  .join(' ')
                  .trim()}
              >
                {title}
              </div>
            ) : null}
            {description ? (
              <>
                {descriptionMode === CONTENT_MODES.dangerouslySetInnerHTML ? (
                  <div
                    className="description-text"
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></div>
                ) : (
                  <div className="description-text">{description}</div>
                )}
              </>
            ) : null}
          </div>
        </div>
        {endIcon || null}
        {showActionIcon ? (
          <div className="icon-wrapper">
            {actionIcon || <div className="action-icon"></div>}
          </div>
        ) : null}
      </div>
      {isWholeListItemClickable ? (
        <button
          className="action-button"
          name={inputName}
          onClick={onClick}
        ></button>
        ) : null}
    </div>
  );
}
export default ListItem;
